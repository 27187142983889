.page-user-preferences{
    .main-window{
        border: 1px solid lightgrey;
        padding: 20px;

    }
    .e-input-group.e-control-wrapper.e-ddl{
        width: 178px !important;
        input{
            font-weight: bold;
        }
    }

    .first-column{
        float: left;
        width: 35%;        
        padding: 20px;
        display: table-cell;
        vertical-align: middle;
        div{
            font-size: 20px;
            font-family: ubuntu;
        }   
        p{
            font-size: 16px;
            font-family: ubuntu;
        }
    }
    .second-column{
        float: left;
        padding: 20px;
        width: 35%;
        display: table-cell;
        vertical-align: middle;
    }
    .row{
        .e-btn{
            text-transform: none;
        }
        &.email-section{
            .second-column{
                height: inherit;
            }
            .e-control.e-btn.e-lib {
                margin-top: 20px;
            }
        }
        &.mfa-auth-section{
            .qrcode{
                margin-top: 20px;
            }
        }
        display: table;
        width: 100%;
        height: 100px;
        margin: auto;
        &:first-child{
            border-bottom: 1px dashed lightgrey;
        }
        &:last-child{
            border-bottom: none;
        }
        
        border-bottom: 1px dashed lightgrey;
        h1{
            font: nobel-regular
        }
        .userSettings-switch{
            display: none !important;
            width: 50px!important;
            height: 24px !important;
        }
        .e-switch-wrapper.e-wrapper{
            top: -35px;
            left: 105px;
        }
        // .e-switch-inner {
        //     height: 30px;
        //     width: 70px;
        //     background: black;
        //     border-radius: 0;
        //     &.e-switch-active, &:hover, .e-switch-on, .e-switch-on:hover{
        //         background: green;
               
        //     }
        // }
        // .e-switch-handle{
        //     height: 20px;
        //     width: 30px;
        //     top: 17px;
        //     left: 4px;
        //     border-radius: 0;
        //     &.e-switch-active{
        //         background: white;
        //         left: 53px
        //     }
        // }
        .e-control.e-btn.e-lib {
            height: 40px;
            font-family: ubuntu;
            font-size: 16px;
            color: white;
            background: black;
            &:hover{
                color: black;
                background: white;
            }

        }
    }
}
