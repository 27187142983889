@import '../Index.scss';

@mixin user-view {
    .user-field {
        display: flex;
        margin: 10px 0;
        label {
            width: 200px;
        }
        button {
            margin-right: 10px;
        }
    }

    & > div > div {
        & > select {
            margin: 15px 0;
        }
        & > .btn.btn-primary {
            padding: 7px;
            margin: 10px;
        }
    }
}

.page-container {
    .short-settlements{
        position: relative;
        .short-settlements-grid{
            &:not(.compact-view){
                .e-rowcell,
                .e-headercell {
                    height: 40px;
                    padding: 8px 10px;
                }
                .e-summarycell{
                    padding: 8px 10px;
                    font-weight: bold;
                    color: black;
                }
            }
        }
        .short-settlements-amnt{
            padding: 10px 0;
            button{
                background-color: black;
                color:white;
                // position: absolute;
                // z-index: 1;
                height: 40px;
                text-transform: none;
                font-size: 15px;
                // left: 30%;
                // top: 1.3%;
                // box-shadow: none;
                // &:hover {
                //     background: rgba(0, 0, 0, 0.12);
                //     border-color: rgba(0, 0, 0, 0.12);
                //     border-radius: 0;
                //     color: rgba(0, 0, 0, 0.87);
                // }
            }
            .pay-disabled{
                button{
                    cursor: not-allowed;
                }
            }
            p{
                margin-top: 10px;
            }
            .amnt{
                span{
                    font-weight: bold;
                    margin-left: 5px;
                }
                margin-left: 5px;
            }
        }
    }
}

.page {
    &.company-information {
        div.banking-information {
            margin-top: 25px;
        }

        .wrapped-input {
            label {
                width: 400px;
            }
        }

        .wrapped-info {
            label {
                width: 400px;
            }
        }
    }

    &.check-lookup {
        .search-section {
            margin-top: 25px;
            display: flex;
            align-items: center;
            color: $gray-medium-2;
            font-family: Arial, Helvetica, sans-serif;
            margin-bottom: 25px;
        }

        .e-grid.ram-grid {
            .void-row {
                .e-rowcell {
                    color: $reliant-red;
                }
                .void-column {
                    display: flex;
                    justify-content: center;
                    .btn.btn-secondary {
                        padding: 4px;
                    }
                }
            }
        }
    }

    &.company-users {
        @include user-view;
    }

    &.company-affiliates {
        @include user-view;

        .dropdown-select {
            margin: 10px 30px 0 0;
        }

        .wrapped-info {
            label {
                width: 200px;
            }
        }

        .printed-name {
            width: 350px;
        }
    }
}
