@import '../Index.scss';

.page.consumer-page, .page.consumer-search-page {
    .payment_error{
        margin-left: 28%;
        color: red;
    }
    .settlement-info-grid{
        thead{
            .e-grouptopleftcell{
               background-color: #000033;;
            }
        }
        tfoot{
            td[index="6"]{
                span{
                    display: none;
                }
            }
        }
        tbody{
           
            tr{
                height: 40px !important;
                &:first-child{
                    .e-templatecell{
                        span{
                            display: none;
                        }
                    }
                }
               
            }
        }
        
    }
    .e-outline {
        height: 25px;
        width: 25px;
    }
    .info-icon {
        background-image: url('../../assets/images/info-circle-solid.png');
        text-indent: -99999px;
        background-repeat: no-repeat;
        text-overflow: none;
        background-size: 100%;
    }
    .advance-pay-icon{
        background-image: url('../../assets/images/payment-solid.png');
        text-indent: -99999px;
        background-repeat: no-repeat;
        text-overflow: none;
        background-size: 100%;
    }
    .alert-icon{
        background-image: url('../../assets/images/alert.png');
        text-indent: -99999px;
        background-repeat: no-repeat;
        color: transparent;
        //background-size: 45%;
        min-height: 24px;
        box-shadow: none;
        margin-top: 2px;
        display: block;

    }
    .edit-icon{
        background-image: url('../../assets/images/edit-solid.png');
        text-indent: -99999px;
        background-repeat: no-repeat;
        text-overflow: none;
    }
    .view-icon{
        background-image: url('../../assets/images/view-solid.png');
        text-indent: -99999px;
        background-repeat: no-repeat;
        text-overflow: none;
        background-size: 90%;
    }
    
    .select-icon{
        background-image: url('../../assets/images/select-outline.png');
        text-indent: -99999px;
        background-repeat: no-repeat;
        text-overflow: none;
        background-size: 90%;
    }
    
    .refund-icon{
        background: url('../../assets/images/refund-outline.png');
        text-indent: -99999px;
        background-repeat: no-repeat;
        text-overflow: none;
        &.scheduled-status{
            background: url('../../assets/images/movetobottom.png');
            background-size: 94%;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    .movetobottom-icon{
        background: url('../../assets/images/movetobottom.png');
        text-indent: -99999px;
        background-repeat: no-repeat;
        text-overflow: none;
    }
    .comfort-grid-view{
        width: fit-content !important;
    }
    .default-grid-view{
        width: 1200px;
    }
    .consumer-general-information {
        .form-label {
            width: 50%;
        }

        .wrapped-info {
            &.available-balance {
                background-color: $gray-lighter;
                background: $gray-lightest-1 0% 0% no-repeat padding-box;
                border: 1px solid $gray-lighter;
                border-radius: 4px;
                padding: 15px;
                width: fit-content;

                span {
                    line-height: 2rem;
                    margin-left: 5px;
                }
            }
        }

        .btn {
            margin-bottom: 15px;
        }

        .view-notes {
            border: 2px solid $white;
        }
    }

    .consumer-notes {
        .ram-notes-title {
            margin-top: 15px;
        }

        label {
            width: 50%;
        }
    }

    .consumer-input-section {
        .settlement-group-row{
            .col.settlement-group{
                display: flex;
                > span{
                    width: 400px !important;
                    margin-bottom: 15px;
                }
            }
            label{
                width: 300px;
                display: block;
            }
            button{
                width: 180px;
                margin: 0 20px 15px;
            }
        }
       
        &.Drafts-tab{
            &.Fees-tab{
                .e-gridcontent{
                    margin-top: 0;
                }
            }
            .e-gridfooter{
                position: absolute;
                top: 86px;
            }
            .e-gridcontent{
                margin-top: 31px;
            }
            .e-grid{
                position: relative;
               
            }
            .compact-grid{
                .e-gridfooter{
                    top: 66px;
                    td{
                        height: 26px;
                        padding: 0 21px !important;
                    }
                }
                .e-gridcontent{
                    margin-top: 26px;
                }
            }
        }
        .wrapped-input {
            label {
                width: 200px;
            }
            .dropdown-select, .e-date-wrapper {
                width: 242px !important;
            }
        }

        .first-name {
            flex: 50%;

            .error-message {
                float: right;
                margin: 0;
            }

            .wrapped-input-container {
                display: block;
            }
        }

        .middle-initial {
            float: right;
            flex: 50%;
            margin-left: 35px;

            .wrapped-input {
                display: block;
            }

            input {
                width: 50px;
                margin-left: 10px;
            }
            label {
                width: 35px;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .drafts-move-months {
            input {
                height: 18px;
                width: 50px;
            }

            .error-message {
                float: left;
                margin: 0;
            }

            .wrapped-input-container {
                display: block;
            }
        }

        .wrapped-info {
            span {
                margin-left: 5px;
            }
        }

        &.settlement-inputs {
            .wrapped-input,
            .wrapped-info {
                label {
                    width: 300px;
                    min-width: 300px;
                    &[for="consumerAccountNumber"]{
                        min-width: unset;
                        width: 230px;
                    }
                }
            }

            .settlement-memo {
                .wrapped-input-container,
                .wrapped-input {
                    width: 100%;
                }

                textarea {
                    width: 100%;
                }
            }

            .creditor-dropdown-wrapper {
                display: flex;

                select {
                    padding-left: 0;
                }

                input {
                    font-family: 'Ubuntu';
                    font-weight: 400;
                    font-size: 1em;
                }

                .btn.btn-new-creditor {
                    padding: 3px;
                    font-size: $font-small;
                    font-weight: 500;
                    height: fit-content;
                }
            }
        }

        .settlement-header-bar {
            margin-bottom: 15px;
        }
    }

    .consumer-input-modal {
        .wrapped-input {
            label {
                width: 150px;
            }
            input {
                width: 100px;
            }
            .input-large {
                width: 210px; // matches width of MaskedTextBoxComponent
            }
            align-items: center;
        }
    }

    .add-consumer-alert-banner {
        margin-top: 5px;
    }

    .e-grid.ram-grid {
        .draft-returned {
            .e-rowcell {
                color: $red !important;
            }
        }
    }

    .button-bar {
        margin: 15px 0px 15px 0px;

        a {
            display: inline-block;
        }
    }

    .consumer-schedule-preview {
        .consumer-schedule-summary {
            margin-top: 15px;
        }
    }

    .e-grid.ram-grid {
        .draft-posted {
            .e-rowcell:not(.total-amount) {
                color: $gray-medium-1;
            }
        }

        &.consumer-grid {
            .e-rowcell,
            .e-headercell {
                height: 40px;
                padding: 8px 10px;
            }

            .e-headercelldiv {
                font-size: 14px;
                padding: 0 0.3em;
            }
            .e-summarycell{
                padding: 8px 10px;
                font-weight: bold;
                color: black;
            }
        }
        &.compact-grid {
            .e-headercelldiv {
                font-size: 14px;
            }
            .e-summarycell{
                font-weight: bold;
                color: black;
            }
        }
     }   
    .consumer-payment-details {
        .e-dialog {
            padding-top: 15px;
        }

        .wrapped-info {
            label {
                width: 300px;
            }
        }
    }

    .creditor-dropdown {
        display: flex;
        .e-dropdownbase .e-list-item {
            line-height: 18px;
            min-height: 18px;
            text-indent: 5px;
            padding-right: 0;
        }
    }
}

.btn.btn-edit-creditor {
    padding: 3px;
    font-size: $font-small;
    font-weight: 500;
}

.creditor-dropdown.address-display {
    color: $royal-blue-dark;

    .name {
        font-weight: 500;
    }

    .street-address-1,
    .street-address-2,
    .city-state-zip {
        font-style: italic;
        font-size: 0.8em;
    }
}

.page.consumer-search-page {
    .search-section {
        display: flex;
        align-items: center;
        color: $gray-medium-2;
        font-family: Arial, Helvetica, sans-serif;
        margin: 10px 0 20px;

        .search-bar {
            width: 800px;
        }
    }

    p {
        color: $royal-blue-dark;
        font-size: $font-medium;
    }
}

.banking-info-tab {
    .card-information {
        margin-top: 20px;
    }

    .title-with-tooltip {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 20px;
    }
    button {
        margin-right: 10px;
    }

    .validate-routing-btn {
        position: relative;
        left: 200px;
    }

    .file-picker {
        margin-right: 10px;
    }
}

.quick-info-popup {
    .row {
        display: flex;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
    .label {
        color: $purple-light;
        width: 200px;
        text-align: left;
    }
    .value {
        margin-left: 20px;
        color: $royal-blue-dark;
        text-align: left;
        width: 100%;
    }
}

.view-existing-cards-popup {
    .credit-card-container {
        background-color: $gray-lightest-1;
        border: 1px solid $gray-lighter;
        border-radius: 4px;

        padding-top: 15px;
        padding-bottom: 19px;
        padding-left: 21px;
        padding-right: 21px;
        margin-bottom: 10px; // spacing between cards

        .card-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: flex-start;

            padding-bottom: 10px;

            div {
                &:not(:last-child) {
                    padding-right: 14px;
                }

                label {
                    color: $gray-medium-2;
                    font-size: $font-small;

                    padding-right: 2px;
                }

                output {
                    color: $royal-blue-dark;
                    font-size: $font-small;
                }
            }
        }

        .file-picker {
            margin-right: 10px;
        }
    }
}

.account-setup-tab {
    .wrapped-info {
        label {
            width: 200px;
        }
    }
}

.support-tickets-tab {
    .add-ticket-view {
        margin-top: 20px;

        button {
            margin-top: 10px;
        }
    }
}

.settlement-button{
    font-size: 14px;
    margin-left: 10px;
    background-color: black;
    color: white;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    display: block;
    text-align: center;
}

.disable-field{
    pointer-events: none;
}

.react-toast-notifications__container.css-13q5zx4-ToastContainer{
    z-index: 1005;
}