@import '../Index.scss';
.chart-title {
    color: $royal-blue-dark;
    margin-top: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 1em;
}
[aria-label="Annotation"]{
    position: relative !important;
    z-index: 0 !important;
}

.dashboard-page {
    .header {
        display: flex;
        justify-content: space-between;
        .title {
            color: $royal-blue-dark;
            font-size: 20px;
        }
        .date-picker {
            width: auto;
        }
    }
}

.dashboard-charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
}

.dashboard-view {
    width: 48%;
    height: 250px;

    .failed-dashboard-display {
        margin-top: 15px;
    }
    .e-maps {
        height: inherit !important;
        padding-top: 15px;
        position: relative;
    }

    #maps_Map_title {
        color: $royal-blue-dark;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
        font-weight: bold;
        font-size: 1em !important;
    }

    #tooltip {
        background: $white;
        background-color: $white;
        padding: 10px;
        box-shadow: 0px 0px 5px #00000014;
        position: relative;
        border: 1px solid $gray-lighter;
        width: max-content;

        .title {
            color: $gray-dark;
            font-size: 16px;
        }

        .breakdown {
            display: flex;
            font-size: 13px;
            .label {
                text-align: left;
                letter-spacing: 0px;
                color: $purple-light;
                margin-right: 5px;
            }

            .value {
                text-align: left;
                letter-spacing: 0px;
                color: $gray-dark;
            }
        }
    }
}
