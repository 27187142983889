@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
@import '../Index.scss';

// Layout
$card-corner-radius: 4px;

// Page
.fee-split-page {
    font-size: $font-medium;

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .fee-group-flex-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: left;
        align-items: top;

        padding-bottom: 10px;

        .dropdown-label {
            color: $royal-blue-dark;
            font-size: $font-medium;
            padding-top: 24px; // Use padding to manually center label with dropdown
        }

        div {
            padding: 4px;

            .row-container {
                display: flex;
                flex-flow: row nowrap;
                justify-content: left;
                align-items: center;

                .dropdown-select {
                    color: $royal-blue-dark;
                    font-size: $font-extra-small;
                    background-color: $gray-lightest-1;
                    border-radius: 4px;
                    width: 100%;
                }
                .api-id {
                    color: $purple-light;
                    font-size: $font-small;
                }

                .edit-name-input {
                    color: $royal-blue-dark;
                    font-size: $font-extra-small;
                    background-color: $gray-lightest-1;
                    border-radius: $card-corner-radius;
                    width: 100%;
                    box-sizing: border-box; // prevent the element width from extending outside parent's bound (when padding is used)
                }
            }
        }
    }

    .fee-card-flex-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;

        background-color: $gray-lightest-1;
        border: 1px solid $gray-lighter;
        border-radius: $card-corner-radius;

        padding-top: 15px;
        padding-bottom: 19px;
        padding-left: 21px;
        padding-right: 21px;
        margin-bottom: 10px; // spacing between cards

        div {
            // Initialize flex basis to 1px, then let each item grow to fit
            flex-basis: 1px;
            flex-grow: 1;

            ul {
                // Spacer bar
                border-left: 1px solid $gray-lighter;
                padding-left: 21px;
                margin-left: 21px;
            }

            .date {
                color: $gray-medium-2;
                font-size: $font-extra-small;
            }

            .name {
                color: $royal-blue-dark;
                font-size: $font-extra-large-1;
                font-weight: bold;
            }

            .hold {
                color: $gray-medium-2;
                font-size: $font-small;
            }

            li {
                color: $purple-light;
                font-size: $font-small;
                padding-bottom: 4px;

                &:last-child {
                    padding-bottom: 0px;
                }
            }
        }
    }
}
