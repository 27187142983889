@import '../Index.scss';

.loading-button {
    display: flex;
    flex-flow: row nowrap;

    .loading-spinner {
        overflow: hidden;
    }
}
