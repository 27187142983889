@import 'Index.scss';

form {
    margin-top: 5px;
    display: flex;
    flex-direction: column;

    .wrapped-input-container {
        display: flex;
    }

    .wrapped-input {
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;

        label {
            min-width: 200px;
            &.required:after {
                content: ' *';
                color: $reliant-red;
            }
        }
    }

    .error-message {
        color: $reliant-red;
        margin: 10px;
        font-style: italic;
        font-size: 0.8em;
        span {
            vertical-align: middle;
        }
    }
}
