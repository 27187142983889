@import '../Index.scss';

/********************************************** Buttons **********************************************/
.btn {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-size: $font-medium;
    font-weight: 500;
    width: fit-content;
    border-radius: 4px;

    &.btn-primary {
        background: $royal-blue-dark;
        border: 2px solid $royal-blue-dark;
        color: $gray-lightest-2;

        &:hover:not(:disabled) {
            background-color: $royal-blue-darker;
            border: 2px solid $royal-blue-darker;
        }
    }

    &.btn-outline-primary {
        background: $white;
        border: 2px solid $royal-blue-dark;
        color: $royal-blue-dark;

        &:hover:not(:disabled) {
            background-color: $purple-light;
            border: 2px solid $royal-blue-dark;
        }
    }

    &.btn-secondary {
        background: $blue-gray-medium 0% 0%;
        border: 2px solid $blue-gray-medium;
        color: $royal-blue-dark;

        &:hover:not(:disabled) {
            background-color: $purple-light;
            border: 2px solid $royal-blue-dark;
        }
    }

    &.btn-danger {
        background: $reliant-red;
        border: 2px solid $reliant-red;
        color: $white;

        &:hover:not(:disabled) {
            background-color: $reliant-red-darker;
            border: 2px solid $reliant-red-darker;
        }
    }

    &.btn-outline-danger {
        background: $white;
        border: 2px solid $reliant-red;
        color: $reliant-red;

        &:hover:not(:disabled) {
            background-color: $reliant-red-lighter;
            border: 2px solid $reliant-red;
            color: $reliant-red;
        }
    }

    &:disabled {
        cursor: unset;
        opacity: 0.6;
    }
}

a.btn {
    text-decoration: none;
}

/********************************************** Inputs **********************************************/
.clickable {
    cursor: pointer;
}

.wrapped-info {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;

    &.stacked {
        display: block;

        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-top: 5px;
            margin-bottom: 0;
        }
    }

    .value {
        color: $royal-blue-dark;
        line-height: 18px;
        font-weight: 400;
    }

    .info {
        color: #696988;
        line-height: 18px;
    }

    &.error-text {
        label,
        span {
            color: $reliant-red;
        }
    }
}

//Dropdown
.dropdown-select {
    color: $royal-blue-dark;
    font-size: $font-extra-small;
    background-color: $white;
    border: 1px solid $gray-lighter;
    border-radius: 4px;

    &:focus {
        border-width: 1px;
    }
}

//Syncfusion dropdown
.e-ddl.e-input-group.e-control-wrapper .e-input {
    font-size: 1em;
    font-weight: 400;
    font-family: 'Ubuntu';
    color: $royal-blue-dark;
}

.e-dropdownbase .e-list-item,
.e-dropdownbase .e-list-item.e-item-focus {
    line-height: 20px !important;
    min-height: 20px !important;
    text-indent: 0px !important;
    padding: 10px !important;
}

//Syncfusion checkboxes
.e-checkbox-wrapper,
.e-css.e-checkbox-wrapper {
    .e-frame {
        background-color: $white;
        border: 1px solid $gray-light-2;
        border-radius: 4px;
        color: $royal-blue-dark;

        &.e-check {
            background: $white;
            border: 1px solid $gray-light-2;
            border-radius: 4px;
            opacity: 1;
            color: $royal-blue-dark;
        }

        &.e-stop {
            color: $royal-blue-dark;

            &:hover {
                color: $royal-blue-dark;
            }
        }

        &:active {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
        }
    }

    &.e-checkicon {
        .e-frame.e-check::before {
            content: '\2714';
        }
    }

    &:hover {
        .e-frame.e-check {
            background-color: $white;
            border: 1px solid $gray-light-2;
            border-radius: 4px;
            color: $royal-blue-dark;
        }
    }
}

/********************************************** Display and Alignment **********************************************/
.float-right {
    float: right;
}

.float-left {
    float: left;
}

.clear-fix::after {
    content: '';
    clear: both;
    display: table;
}

.flex {
    display: flex;
    &.edit-checkbox{
        &.hide{
            display: none;
        }
        &.disabled{
            .e-date-wrapper{
                pointer-events: none;
                opacity: 0.5;
            }
        }
        .wrapped-input-container:last-child{
            .wrapped-input{
                // flex-direction: row-reverse;
                label{
                    margin-top: 3px;
                    width: 35px;
                    min-width: 35px;
                }
    
            }
        }
    }
}

.row {
    display: flex;
}

.col-2 {
    flex: 16%;
    padding-right: 5px;
}

.col-3 {
    flex: 25%;
    padding-right: 5px;
}

.col-4 {
    flex: 33%;
    padding-right: 5px;
}

.col-6 {
    flex: 50%;
    padding-right: 5px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-left-10 {
    margin-left: 10px;
}

/********************************************** Tabs **********************************************/
.tab-link {
    padding-bottom: 15px;
    margin: 15px 30px 15px 0px;
    color: $purple-lighter;
    text-decoration: none;
    display: inline-block;

    &:visited {
        color: $purple-lighter;
    }

    &:hover {
        color: $gray-dark;
    }

    &.active {
        color: $gray-dark;
        border-bottom: 2px solid $royal-blue-dark;
    }
}

/********************************************** Search **********************************************/

.search-bar {
    display: flex;
    align-items: center;

    border: 1px solid $gray-lighter;
    border-radius: 19px;

    height: 38px;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 20px;
    img {
        height: 1em;
        padding-right: 5px;
        cursor: pointer;
    }
    input {
        text-align: left;
        letter-spacing: 0px;
        font-size: 1em;
        font-weight: 300;
        border: 0;
        width: 100%;
    }

    input[type='text'],
    input[type='number'] {
        &:focus {
            outline: none !important;
            border: 0;
        }
    }

    &.search-focus {
        border: 1px solid $royal-blue-dark;
    }
}

/********************************************** Grids **********************************************/
.e-grid.ram-grid {
    border: 1px solid $gray-light-3;
    border-radius: 4px;

    .e-table {
        background-color: #e5e8e8;
        border-color: $gray-light-3;
    }

    .e-headercell {
        background-color: $royal-blue-dark;
        color: $white;
    }

    .e-headercelldiv {
        font-size: $font-small;
        font-weight: 300;
    }

    td.e-active {
        background-color: $blue-gray-medium-2;
    }

    .e-rowcell {
        color: $gray-dark;
    }

    .error-text {
        .e-rowcell {
            color: $reliant-red !important;
        }
    }

    .draft-posted {
        .e-rowcell {
            color: $sky-blue-medium !important;
        }
    }

    .e-btn.e-disabled {
        display: none;
    }

    .e-gridheader {
        .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
            color: $white;
        }
    }
    .e-altrow{
        background-color: #fafafa; 
    }
}

/********************************************** Syncfusion Radion Buttons and Groups **********************************************/
.rdo-group {
    list-style-type: none;
    display: grid;
    margin: 0;
    padding: 0;
}

.e-radio-wrapper {
    margin: 0 0 5px 0;

    .e-radio + label::before {
        background-color: $white;
        border-color: $gray-medium-3;
        border: 1px solid;
    }

    .e-radio:checked + label::before {
        background-color: $white;
        border-color: $reliant-red;
    }

    .e-radio:checked + label::after {
        background-color: $reliant-red;
        border-color: $reliant-red;
    }

    .e-radio:checked:focus + label::before,
    .e-radio:checked + label:hover::before {
        background-color: $white;
        color: $reliant-red;
        border-color: $reliant-red;
    }

    .e-radio + label .e-label {
        font-family: $ubuntu;
        font-size: $font-extra-small;
        color: $royal-blue-dark;
    }
}

/********************************************** Display Text **********************************************/
.error-text {
    color: $reliant-red !important;
}
