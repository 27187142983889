@import '../Index.scss';

.e-tooltip:not(.e-grid) {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
}

.e-tooltip-wrap {
    border-radius: 25px;
    opacity: 1;
    padding: 5px;

    &.e-popup {
        background: $royal-blue-dark 0% 0% no-repeat padding-box;
        border: none;
    }

    .e-tip-content {
        color: $white;
        font-size: 13px;
        border-radius: 25px;
        text-align: center;
        letter-spacing: 0px;
        opacity: 1;
        font-weight: 400;
        padding: 8px;
    }

    .e-arrow-tip-inner.e-tip-top,
    .e-arrow-tip-outer.e-tip-top {
        color: $royal-blue-dark;
        border: none;
    }

    .e-arrow-tip-inner.e-tip-left,
    .e-arrow-tip-outer.e-tip-left {
        color: $royal-blue-dark;
        border: none;
    }

    .e-arrow-tip-inner.e-tip-right,
    .e-arrow-tip-outer.e-tip-right {
        color: $royal-blue-dark;
        border: none;
    }

    .e-arrow-tip-inner.e-tip-bottom,
    .e-arrow-tip-outer.e-tip-bottom {
        color: $royal-blue-dark;
        border: none;
    }
}
