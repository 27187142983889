@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
@import '../Index.scss';

.reporting-page {
    font-size: $font-medium;

    .top-row {
        margin-bottom: 20px;
        align-items: center;
    }

    label {
        width: 150px;
    }
    p {
        color: $royal-blue-dark;
        max-width: 400px;
        margin: 0;
    }
    .report-grid {
        margin-top: 15px;
        .e-summarycell{
            padding: 8px 10px;
            font-weight: bold;
            color: black;
        }
        &:not(.compact-view){
            .e-rowcell,
            .e-headercell {
                height: 40px;
                padding: 8px 10px;
            }
            .e-summarycell{
                padding: 8px 10px;
                font-weight: bold;
                color: black;
            }
        }
        &.comfort-grid-view{
            width: fit-content !important;
        }
        &:not(.comfort-grid-view){
            width: 1500px;
        }
    }
}
