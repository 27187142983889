@import '../Index.scss';

.alert-banner {
    background-color: $reliant-red-translucent;
    border: 1px solid $reliant-red;
    border-radius: 4px;
    padding: 10px 21px;
    display: flex;

    img {
        height: 1rem;
        margin-right: 5px;
    }

    p {
        color: $reliant-red;
        font-weight: 400;
        font-size: 1rem;
        margin: 0;
    }
}
