@import '../Index.scss';

div.checkbox-array
{
    .checkbox-array-item {
        label
        {
            text-align: left;
            letter-spacing: 0px; 
            font-size: 1em;
            font-weight: 400;
            color: $royal-blue-dark;
            line-height: 18px;
            vertical-align: middle;
        }

        input[type=checkbox]
        {
            vertical-align: middle;
            float: left;
        }
    }

    ul {
        list-style: none; 
        margin: 0;
        padding: 0;
        
        li { 
            display:inline-block;
            margin-right: 25px;
        }
    }
}