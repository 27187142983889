@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-notifications/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
@import 'Index.scss';

html,
#root {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
}

.page-container {
    width: 100%;
    overflow-y: auto;
    padding: 25px;
    margin-top: $topbar-height;
    margin-left: $sidebar-width;
    &.close {
        margin-left:60px;
    }
}

.app {
    height: 100%;
}

.page-title {
    color: $royal-blue-dark;
    font-weight: 700;
    text-align: left;
    letter-spacing: 0px;
    margin: 0;
}
.login-page{
    .login.card{
        position: relative;
    }
    .spinner-container{
        position: absolute;
    }
    .layout-root{
        display: grid;
    }
}

.layout-root {
    display: flex;
    flex-direction: row;
    height: 100%;

    .top-bar {
        display: flex;
        justify-content: space-between;
    }

    .layout-first-column {
        z-index: 1;
        &:not(.sideBarOpen){
            .e-acrdn-header {
                padding: 0 15px;
            }
        }
        &.sideBarOpen{
            width: $sidebar-width;
            .e-acrdn-header {
                .e-acrdn-header-content {
                    .header-icon {
                        margin-right: 10px !important;
                    }
                }
            }
        }
        top:94px;
        height: 100%;
        width: 60px;       
        background-color: $blue-gray-medium;
        position: fixed;

        .e-accordion {
            border: 0px;
        }

        .e-acrdn-item {
            background-color: $blue-gray-medium;
            cursor: pointer;

            &:not(.e-expand-state) {
                height: 60px;
            }

            .e-toggle-icon {
                display: none;
            }

            .e-acrdn-header {
                display: flex;
                justify-content: center;
                color: $gray-medium-3;

                .e-acrdn-header-content {
                    width: 150px;

                    .header-icon {
                        margin-right: -15px;
                        vertical-align: middle;
                    }

                    h3 {
                        font-weight: 400;
                        text-align: left;
                        letter-spacing: 0px;
                        font-size: 15px;
                        vertical-align: middle;
                    }

                    &.e-active {
                        h3 {
                            font-weight: 700;
                            color: $gray-darkest;
                        }
                    }
                }
            }

            .sidenav-section-content {
                display: flex;
                flex-direction: column;
            }

            &.e-select.e-active,
            &.e-select.e-item-focus,
            &.e-select.e-expand-state {
                border: none;

                .e-acrdn-header {
                    background: $blue-gray-lighter;
                    border: none;
                }

                h3 {
                    font-weight: 700;
                    color: $gray-darkest;
                }
            }

            .e-acrdn-panel {
                .e-acrdn-content {
                    padding: 0;
                }

                a {
                    padding-left: 20%;
                    color: $gray-medium-3;
                    font-size: 14px;
                    font-weight: 400;
                    text-decoration: none;
                    text-align: left;
                    line-height: 1.7rem;
                }
            }
        }
    }
}

// For <input> elements where type="file", apply styles to the "Choose file" button that renders within the <input>
::-webkit-file-upload-button {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-size: $font-medium;
    font-weight: 500;
    width: fit-content;
    border-radius: 4px;

    background: $blue-gray-medium 0% 0%;
    border: 2px solid $blue-gray-medium;
    color: $royal-blue-dark;

    &:hover {
        background-color: $purple-light;
        border: 2px solid $royal-blue-dark;
    }
}
